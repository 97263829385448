.web-tooltip
  position: absolute
  display: none
  flex-direction: column
  padding: 7.5px 10px
  border-radius: 4px
  font-weight: normal
  z-index: 5
  color: $white
  .tooltip-title
    font-size: 14px
  .tooltip-list
    margin-top: 5px
    li
      display: flex
      align-items: center
      font-size: 12px
      &:not(:last-child)
        margin-bottom: 5px
      span
        display: flex
        margin-right: 10px !important
        &[class$='icon-'],
        &[class*='icon-']
          font-size: 18px !important
    &.classic li
        display: list-item
        list-style: disc
        margin-left: 18px
  &.short
    width: min-content
    .tooltip-title,
    .tooltip-regular
      white-space: nowrap
  &.medium
    width: 220px
    .tooltip-title
      white-space: normal
  &.medium-archive
    width: 270px
    .tooltip-title
      white-space: normal
  &.wide
    width: 375px
    .tooltip-title
      white-space: normal
  &.white,
  &.grey
    background-color: $white
    border: 1px solid $border-grey
    color: $secondary-grey
  &.green
    background-color: $bright-green
  &.orange
    background-color: $bright-orange
  &.orange-border
    background-color: $white
    border: 1px solid $bright-orange
    color: $bright-orange
  &.red
    background-color: $bright-red
  &.blue
    background-color: $tempton-blue
  &.border-blue
    background-color: $white
    border: 1px solid $tempton-blue
    color: $secondary-grey
  &.left
    @media (min-width: 768px)
      right: 100%
      top: 50%
      transform: translate(0, -50%)
      margin-right: 5px
  &.left-sm
    @media (max-width: 768px)
      right: 100%
      top: 50%
      transform: translate(0, -50%)
      margin-right: 5px
  &.left-m
    @media (max-width: 1100px)
      bottom: 100%
      left: -34px
      margin-bottom: 10px
  &.right
    @media (min-width: 450px)
      left: 100%
      top: 50%
      transform: translate(0, -50%)
      margin-left: 5px
  &.right-sm
    @media (max-width: 768px)
      left: 100%
      top: 50%
      transform: translate(0, -50%)
      margin-left: 5px
  &.bottom
    @media (min-width: 768px)
      top: 100%
      left: 50%
      transform: translate(-50%, 0)
      margin-top: 5px
  &.bottom-sm
    @media (max-width: 768px)
      top: 100%
      left: 50%
      transform: translate(-50%, 0)
      margin-top: 5px
  &.bottom-left
    @media (min-width: 768px)
      top: 100%
      left: 0
      margin-top: 5px
  &.left-1000
    @media (max-width: 1000px)
      right: 100%
      left: unset
      top: 50%
      transform: translate(0, -50%)
      margin-right: 5px

.tooltip-icon
  display: flex
  align-items: center
  justify-content: center
  position: relative
  font-size: 20px
  cursor: pointer
  @media (min-width: 450px)
    &:hover .web-tooltip
      display: flex

.tooltip-hover
  position: relative
  @media (min-width: 450px)
    &:hover .web-tooltip
      display: flex
.tooltip-hover-absolute
  position: absolute
  @media (min-width: 450px)
    &:hover .web-tooltip
      display: flex
