[class^="absolute"],
[class*=" absolute"]
  position: absolute

[class^="fixed"],
[class*=" fixed"]
  position: fixed

.relative
  position: relative

.sticky
  position: sticky

.fixed,
.absolute
  &-right-center
    right: 0
    top: 50%
    transform: translate(0, -50%)
  &-bottom-center
    bottom: 0
    left: 50%
    transform: translate(-50%, 0)
  &-center
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
  &-full-top-center
    top: 100%
    left: 50%
    transform: translate(-50%, 0)
  &-full
    top: 0
    right: 0
    bottom: 0
    left: 0
  &-full-height
    top: 0
    bottom: 0

.popup-overflow-grey
  background-color: rgb(0 0 0 / 25%)
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

.top-0
  top: 0
.right-0
  right: 0
.bottom-0
  bottom: 0
.left-0
  left: 0

.top-5
  top: 5px
.bottom-5
  bottom: 5px
.right
  &-5
    right: 5px
  &-10
    right: 10px
  &-20
    right: 20px
  &-40
    right: 40px
.left-10
  left: 10px
.top-20
  top: 20px
.top-40
  top: 40px

.full-postion-auto
  top: 20px
  right: 20px
  bottom: 20px
  left: 20px
  @media (max-width: $laptop-view)
    top: 15px
    right: 15px
    bottom: 15px
    left: 15px 
  @media (max-width: $tablet-view)
    top: 12px
    right: 12px
    bottom: 12px
    left: 12px
  @media (max-width: $mobile-view)
    top: 7.5px
    right: 7.5px
    bottom: 7.5px
    left: 7.5px
