$text-small-icon: 20px
$text-icon: 24px

$text-xs: 10px
$text-s:  12px
$text-m:  14px
$text-l:  16px
$text-xl: 18px

$screens: ('-laptop': $laptop-view, '-tablet': $tablet-view, '-mobile': $mobile-view, 'none':'')
$sizes: ('xs': $text-xs, 's': $text-s, 'm': $text-m, 'l': $text-l, 'xl': $text-xl)

@each $screen-key, $screen in $screens
  @each $size-key, $size in $sizes
    @if $screen-key == 'none'
      .text-#{$size-key},
      .font-#{$size-key}
        font-size: $size
    @else
      @media (max-width: $screen)
        .text#{$screen-key}-#{$size-key}
          font-size: $size !important

.font-icon
  font-size: $text-icon
.font-small-icon
  font-size: $text-small-icon
.font-8
  font-size: 8px
.font-14
  font-size: 14px
.font-30
  font-size: 30px
.font-35
  font-size: 35px
.font-80
  font-size: 80px
.font-100
  font-size: 100px

.roboto
  font-family: 'Roboto', sans-serif
  font-weight: 400

.text
  &-left
    text-align: left
  &-center
    text-align: center
  &-right
    text-align: right

.lh-1
  line-height: 1
.line-height
  &-40
    line-height: 40px

.color
  &-white
    color: $white
    &-i
      color: $white !important
  &-black
    color: $black
  &-green
    color: $bright-green
  &-orange
    color: $bright-orange
  &-red
    color: $bright-red
    &-i
      color: $bright-red !important
  &-blue
    color: $tempton-blue
  &-light-blue
    color: $light-blue
  &-title-grey
    color: $title-grey
  &-border-grey
    color: $border-grey
  &-secondary-grey
    color: $secondary-grey
  &-disabled
    color: $disabled
  &-time-tracking-blue
    color: $tempton-blue
  &-tempton-connect-blue
    color: $tempton-connect-blue
  &-salary-reports-grey
    color: $salary-reports-grey
  &-tempton-documents-blue
    color: $tempton-documents-blue

  &-tempton-jet-purple
    color: $tempton-jet-purple
  &-tempton-jet-purple-light
    color: $tempton-jet-purple-light
  &-tempton-connect-mint
    color: $tempton-connect-mint
  &-tempton-connect-mint-light
    color: $tempton-connect-mint-light
  &-tempton-portals-blue
    color: $tempton-portals-blue
  &-tempton-portals-blue-light
    color: $tempton-portals-blue-light
  &-tempton-connect-blue
    color: $tempton-connect-blue
  &-tempton-connect-blue-light
    color: $tempton-connect-blue-light

.text
  &-450
    font-weight: 450
  &-500
    font-weight: 500
.bold
  font-weight: bold

.crossed
  text-decoration: line-through
.underline
  text-decoration: underline

.word-no-wrap
  white-space: nowrap
.word-keep-all
  word-break: keep-all
.word-break-all
  word-break: break-all
.word-break-word
  word-break: break-word

.ellipsis
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.ellipsis-xs
  @media (max-width: 450px)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
