@import 'colors'

.mat-datepicker-toggle
  display: flex
  align-items: center
  justify-content: center
  &.mat-datepicker-toggle-active
    color: $tempton-blue
  & > button
    width: 24px !important
    height: 24px !important
    padding: 0 !important
    line-height: unset
    outline: none
    & > svg
      position: absolute
      top: 0
      left: 0
    .mat-button-wrapper
      display: flex
      align-items: center
      justify-content: center

.cdk-overlay-container .mat-datepicker-popup
  .mat-calendar-body-active
    .mat-calendar-body-today
      background-color: $tempton-light-blue !important
  .mat-calendar-body
    td
      vertical-align: middle
  .mat-calendar-body-selected
    background-color: $tempton-blue
  .mat-calendar-body-cell
    &:not(.mat-calendar-body-disabled)
      &:hover
        .mat-calendar-body-cell-content
          &:not(.mat-calendar-body-selected)
            &:not(.mat-calendar-body-comparison-identical)
              background-color: $tempton-light-blue
  .mat-calendar-body-today
    &:not(.mat-calendar-body-selected)
      &:not(.mat-calendar-body-comparison-identical)
        border-color: $tempton-blue
