.custom-checkbox
  position: absolute
  z-index: -1
  opacity: 0
  & + label
    display: inline-flex
    align-items: center
    user-select: none
    margin: 0
    & > span
      margin-right: 5px
    &::before
      content: ''
      font-family: 'icon-font'
      font-weight: normal
      display: flex
      border: 1px solid $border-grey
      border-radius: 2px
      margin-right: 8px

  &:checked+label
    &::before
      content: '\50'  //tick
  &.small
    font-size: 12px
    &+label::before
      font-size: 12px
      width: 16px
      height: 16px
      align-items: center
      justify-content: center
  &.big
    font-size: 14px
    &+label::before
      font-size: 13px
      min-width: 20px
      height: 20px
      padding-left: 3px
    &:checked+label::before
      padding-top: 2px
  &.white
    &:not(:disabled) + label:hover::before
      border-color: $title-grey
    &:checked+label::before
      color: $secondary-grey
      background-color: $white
  &.blue
    &:not(:disabled) + label:hover::before
      opacity: .75
    &:not(:disabled):not(:checked) + label:hover::before
      border-color: $tempton-blue
    &:checked+label::before
      border-color: $tempton-blue
      background-color: $tempton-blue
      color: $white
  &.blue-border
    &:not(:disabled) + label:hover::before
      opacity: .75
    &:not(:disabled):not(:checked) + label:hover::before
      border-color: $tempton-blue
    &:checked+label::before
      border-color: $tempton-blue
      color: $tempton-blue
  &:disabled+label::before
    background-color: $secondary-grey
  &.radio+label::before
    border-radius: 50%
    margin-right: 10px