@import 'colors'

.bg-white
  background-color: $white
.bg-grey
  background-color: $table-bg-grey
.bg-light-red
  // background-color: $light-red
.bg-red-50
  background-color: $bright-red-50
.bg-red
  background-color: $bright-red
.bg-dark-red
  background-color: $dark-red
.bg-orange
  background-color: $bright-orange
.bg-light-green
  background-color: $light-green
.bg-green-50
  background-color: $bright-green-50
.bg-green
  background-color: $bright-green
.bg-dark-green
  background-color: $dark-green
.bg-secondary
  background-color: $secondary-grey

.bg-blue
  background-color: $tempton-blue
.bg-light-blue
  background-color: $tempton-time-tracking-blue-light

.bg-time-tracking-blue
  background-color: $tempton-blue
  &-light
    background-color: $tempton-time-tracking-blue-light

.bg-tempton-connect-blue
  background-color: $tempton-connect-blue
  &-light
    background-color: $tempton-documents-blue-light

.bg-salary-reports-grey
  background-color: $salary-reports-grey
  &-light
    background-color: $salary-reports-grey-light

.bg-tempton-documents-blue
  background-color: $tempton-documents-blue
  &-light
    background-color: $tempton-documents-blue-light

.bg
  &-tempton-jet-purple
    background-color: $tempton-jet-purple
    &-light
      background-color: $tempton-jet-purple-light
  &-tempton-connect-mint
    background-color: $tempton-connect-mint
    &-light
      background-color: $tempton-connect-mint-light
  &-tempton-portals-blue
    background-color: $tempton-portals-blue
    &-light
      background-color: $tempton-portals-blue-light
  &-tempton-connect-blue
    background-color: $tempton-connect-blue
    &-light
      background-color: $tempton-connect-blue-light
