@import 'colors'

button
  display: flex
  justify-content: center
  text-align: center
  align-items: center
  border-radius: 4px
  border: none
  background-color: $white
  font-size: 12px
  font-family: 'Poppins'
  height: 36px
  &:focus
    outline: 0 !important
  &[type="reset"]
    padding: 0
    height: auto
    color: inherit

  &.back-btn
    min-width: 90px
    flex: 0 1 120px
    background-color: $secondary-grey
    color: $white
  &.short-btn
    max-width: 120px
    min-width: 80px
    width: 100%
  &.medium-btn
    width: 165px
  &.medium-btn-flex
    flex: 1 0 80px
    min-width: 80px
    max-width: 150px
  &.wide-btn-flex
    flex: 1 0 100px
    min-width: 100px
    max-width: 180px
  &.wide-btn
    max-width: 180px
    min-width: 90px
    flex: auto
  &.single-wide-btn
    width: 240px
    height: 45px
    font-size: 16px
  &.btn-full-tablet
    @media (max-width: $tablet-view)
      width: 100%
      max-width: unset
      flex: auto

  &.with-extra-button
    padding: 0
    &:hover
      opacity: 1 !important
    & > span:first-child
      flex: 1
    & > span:last-child
      display: flex
      align-items: center
      justify-content: center
      height: 100%
      width: 30px
      background-color: $tempton-blue
      color: $white
      font-size: 20px
    & > span:hover
      opacity: 0.8

  &.white
    border: 1px solid $border-grey
    color: $secondary-grey
  &.grey
    background-color: $border-grey
    color: $white
  &.grey-border
    border: 1px solid $secondary-grey
    color: $secondary-grey
  &.blue
    background-color: $tempton-blue
    color: $white
  &.blue-no-border
    color: $tempton-blue
  &.blue-thin-border
    color: $tempton-blue
    border: 1px solid $tempton-blue
  &.red
    background-color: $bright-red
    color: $white
  &.red-thin-border
    color: $bright-red
    border: 1px solid $bright-red
  &.orange
    background-color: $bright-orange
    color: $white
  &.green
    background-color: $bright-green
    color: $white

  &:disabled,
  &:placeholder:disabled
    background-color: $table-bg-grey
    color: $disabled
    border-color: $table-bg-grey
  &:not(:disabled):hover
    opacity: 0.75
  .icon-search,
  .icon-excel
    margin-right: 10px
    font-size: 23px
    display: flex
    @media (max-width: 1280px)
      margin-right: 5px

button[disabled]
  background-color: $table-bg-grey
  color: $disabled
  border-color: $table-bg-grey

form
  .form-group-row
    display: flex
    align-items: center
    margin-bottom: 5px
    .field-label
      margin-right: 10px
  .icon-bin
    font-size: 20px
    display: flex
    margin-left: 10px
    margin-right: 5px
    cursor: pointer
    @media (max-width: 1280px)
      margin-left: 5px
      margin-right: 5px
    &:hover
      opacity: 0.75

input:focus
  outline: none

input:not([type='checkbox']),
select
  height: 36px
  display: flex
  justify-content: center
  border-radius: 4px
  color: $title-grey
  border: 1px solid $border-grey
  padding: 10px
  font-size: 12px
  font-family: 'Poppins'
  &:not([class*='bg-'])
    background-color: $white
  &.transparent
    border: none
    color: $secondary-grey
  &.red,
  &.ng-dirty.ng-invalid
    border: 1px solid $bright-red
  &.blue
    border: 1px solid $tempton-blue
  &.text-m
    font-size: 14px
  &:disabled
    background-color: $table-bg-grey
    &::-webkit-input-placeholder
      color: $disabled

select
  padding: 0 10px
  text-overflow: ellipsis
  display: block

textarea
  width: 100%
  background-color: $table-bg-grey
  border-radius: 4px
  padding: 17px 76px 13px 17px
  border: none
  color: #616668
  font-size: 14px
  font-family: 'Poppins'
  min-height: 60px
  &:focus
    outline: none
  &:disabled
    opacity: 1
  &.resize-none
    resize: none

// .mat-icon-button.mat-button-base:not(.mat-calendar-previous-button):not(.mat-calendar-next-button)
//   height: 36px !important
//   width: 30px !important
//   line-height: inherit !important
//   display: flex !important