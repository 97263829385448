@charset "UTF-8";

@font-face {
  font-family: "icon-font";
  src:url("assets/fonts/icon-font.eot");
  src:url("assets/fonts/icon-font.eot?#iefix") format("embedded-opentype"),
    url("assets/fonts/icon-font.woff") format("woff"),
    url("assets/fonts/icon-font.ttf") format("truetype"),
    url("assets/fonts/icon-font.svg#icon-font") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "icon-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "icon-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dashboard:before {
  content: "\61";
}
.icon-circle-tick:before {
  content: "\62";
}
.icon-circle-cross:before {
  content: "\63";
}
.icon-circle-time:before {
  content: "\64";
}
.icon-tn-plus:before {
  content: "\65";
}
.icon-warning:before {
  content: "\66";
}
.icon-users:before {
  content: "\69";
}
.icon-picture:before {
  content: "\6a";
}
.icon-user:before {
  content: "\6b";
}
.icon-mail-config:before {
  content: "\6c";
}
.icon-car:before {
  content: "\6d";
}
.icon-help:before {
  content: "\6e";
}
.icon-comment:before {
  content: "\6f";
}
.icon-circle-question:before {
  content: "\70";
}
.icon-enter:before {
  content: "\71";
}
.icon-tab:before {
  content: "\72";
}
.icon-branch:before {
  content: "\73";
}
.icon-exit:before {
  content: "\74";
}
.icon-archive:before {
  content: "\75";
}
.icon-print:before {
  content: "\76";
}
.icon-excel:before {
  content: "\77";
}
.icon-camera:before {
  content: "\78";
}
.icon-note:before {
  content: "\79";
}
.icon-mitarbeiter:before {
  content: "\7a";
}
.icon-euro:before {
  content: "\41";
}
.icon-date:before {
  content: "\43";
}
.icon-rotate-cw:before {
  content: "\44";
}
.icon-rotate-ccw:before {
  content: "\45";
}
.icon-time-tracking:before {
  content: "\46";
}
.icon-bin:before {
  content: "\47";
}
.icon-edit:before {
  content: "\48";
}
.icon-time-tracking-add:before {
  content: "\49";
}
.icon-reload:before {
  content: "\4a";
}
.icon-plus:before {
  content: "\4b";
}
.icon-minus:before {
  content: "\4c";
}
.icon-expand:before {
  content: "\4d";
}
.icon-releases:before {
  content: "\4e";
}
.icon-circle-user:before {
  content: "\4f";
}
.icon-tick:before {
  content: "\50";
}
.icon-cross:before {
  content: "\51";
}
.icon-debug:before {
  content: "\53";
}
.icon-phone:before {
  content: "\54";
}
.icon-arrow-down:before {
  content: "\55";
}
.icon-arrow-up:before {
  content: "\56";
}
.icon-arrow-right:before {
  content: "\57";
}
.icon-arrow-left:before {
  content: "\58";
}
.icon-bars:before {
  content: "\59";
}
.icon-dots:before {
  content: "\5a";
}
.icon-save:before {
  content: "\30";
}
.icon-gear:before {
  content: "\31";
}
.icon-arrow-big-right:before {
  content: "\32";
}
.icon-search:before {
  content: "\33";
}
.icon-page:before {
  content: "\34";
}
.icon-holiday:before {
  content: "\37";
}
.icon-holiday-add:before {
  content: "\67";
}
.icon-tempton:before {
  content: "\68";
}
.icon-circle-plus:before {
  content: "\38";
}
.icon-circle-fail:before {
  content: "\52";
}
.icon-circle-info:before {
  content: "\35";
}
.icon-licence-plate:before {
  content: "\39";
}
.icon-copy:before {
  content: "\21";
}
.icon-tempton-connect:before {
  content: "\23";
}
.icon-tempton-connect-dashboard:before {
  content: "\24";
}
.icon-tempton-jet:before {
  content: "\42";
}
.icon-tempton-portals:before {
  content: "\36";
}
.icon-eye:before {
  content: "\22";
}
.icon-star:before {
  content: "\25";
}
.icon-star-filled:before {
  content: "\26";
}
.icon-thumb-down:before {
  content: "\27";
}
.icon-thumb-up:before {
  content: "\28";
}
.icon-thumbs-up-down:before {
  content: "\29";
}
