$white: #FFFFFF
$black: #000000
$main-bg-grey: #F0F1F6

$dim-red:         #DE440B1A
$bright-red-50:   #de440b80
$bright-red:      #DE440B
$dark-red:        #B73809
$dim-green:       #95C11F1A
$light-green:     #F4F9E8
$bright-green-50: #95c11f80
$bright-green:    #95C11F
$dark-green:      #7DA21A
$dim-orange:      #FF92281A
$light-orange:    #fff4e9
$bright-orange:   #FF9228
$dark-orange:     #EA821C
$bright-purple:   #6D69A0
$dark-purple:     #534F7B
$bright-blue:     #3797bb
$dark-blue:       #1b729e

$title-grey:      #575756
$border-grey:     #CED4DA
$table-bg-grey:   #F0F0F0
$secondary-grey:  #7D8B92
$disabled:        #BEBEBE
$sidebar-separator-light-grey: #EFEFEF

$tempton-blue:       #0099A8
$tempton-light-blue: #0099a84d
$tempton-dark-blue:  #026f79

$light-blue: #CCEBEE

$tempton-time-tracking-blue-light: #BFE7E9

$salary-reports-grey:          #928f7a
$salary-reports-grey-light:    #E5E6DF
$tempton-connect-blue:         #00678f
$tempton-connect-blue-light:   #BFDBE7
$tempton-documents-blue:       #60a7d0
$tempton-documents-blue-light: #D7E9F3

$laptop-view: 1280px
$tablet-view: 768px
$mobile-view: 450px
$open-sidebar-tablet: 1000px

$default-icon-size: 24px

$border-radius-default: 4px
$size-l: 20px
$size-m: 16px
$size-s: 12px
$size-xs: 8px
$size-xxs: 4px

$tempton-jet-purple:         #2F375F
$tempton-jet-purple-light:   #E0E0E7
$tempton-connect-mint:       #2799A8
$tempton-connect-mint-light: #D9F0F2
$tempton-portals-blue:       #5583AD
$tempton-portals-blue-light: #E6EDF3
$tempton-connect-blue:       #60A7D0
$tempton-connect-blue-light: #E6F2F8
