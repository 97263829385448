[class^="am-flex"],
[class*=" am-flex"]
  display: flex

@mixin flexbox($screen: '', $important: '')
  .am-flex#{$screen}
    &-inline
      display: inline-flex #{$important}
    &-row
      flex-direction: row #{$important}
    &-row-reverse
      flex-direction: row-reverse #{$important}
    &-column
      flex-direction: column #{$important}
    &-column-reverse
      flex-direction: column-reverse #{$important}

    &-justify
      justify-content: space-between #{$important}
      &-evenly
        justify-content: space-evenly #{$important}
      &-center
        justify-content: center #{$important}
      &-start
        justify-content: flex-start #{$important}
      &-end
        justify-content: flex-end #{$important}
      &-stretch
        justify-content: stretch #{$important}

    &-align
      align-items: center #{$important}
      &-center
        align-items: center #{$important}
      &-start
        align-items: flex-start #{$important}
      &-end
        align-items: flex-end #{$important}
      &-self
        &-stretch
          align-self: stretch #{$important}

    &-basis
      &-auto
        flex-basis: auto #{$important}
    &-shrink
      &-0
        flex-shrink: 0

    &-center
      align-items: center #{$important}
      justify-content: center #{$important}
    &-wrap
      flex-wrap: wrap #{$important}
    &-wrap-reverse
      flex-wrap: wrap-reverse #{$important}
    &-nowrap
      flex-wrap: nowrap #{$important}
  .am-flex-0#{$screen}
    flex: 0 #{$important}
  .am-flex-1#{$screen}
    flex: 1 #{$important}
  .am-flex-2#{$screen}
    flex: 2 #{$important}
  .am-flex-quatro#{$screen}
    flex: 1 1 25% #{$important}
  .am-flex-max-content#{$screen}
    flex: 1 1 max-content #{$important}

$screens: ('none':'', '-laptop': $laptop-view, '-tablet': $tablet-view, '-mobile': $mobile-view)
@each $screen-key, $screen in $screens
  @if $screen-key == 'none'
    @include flexbox()
  @else
    @media (max-width: $screen)
      @include flexbox($screen-key, '!important')

@for $i from 1 to 7
  .gap#{$i * 5}
    gap: #{$i * 5}px

.gap0
  gap: 0 !important
  &-mobile
    @media (max-width: $mobile-view)
      gap: 0 !important

.gap2
  gap: 2px

.gap
  &5
    &-1000
      @media (min-width: $tablet-view+1) and (max-width: 1000px)
        gap: 5px !important
    &-laptop
      @media (max-width: $laptop-view)
        gap: 5px !important
  &10
    &-laptop
      @media (max-width: $laptop-view)
        gap: 10px !important

.gap50
  gap: 50px

@for $i from 1 to 8
  .order-#{$i}
    order: #{$i}

.flex-1
  flex: 1

.am-flex
  &-full
    flex: 0 0 100% !important
  &-auto
    flex: auto
    &-mobile
      @media (max-width: $mobile-view)
        flex: auto !important
  &-35
    flex: 0 0 35px !important
  &-130
    flex: 0 0 130px
  &-column-1000
    @media (min-width: $tablet-view+1) and (max-width: 1000px)
      flex-direction: column
  &-370
    flex: 0 1 370px

$sizes: ('xxs': 4, 'xs': 8, 's': 12, 'm': 16, 'l': 20, 'xl': 24, 'xxl': 32, 'xxxl': 40)
@each $size-key, $size in $sizes
  .gap-#{$size-key},
  .gap#{$size}
    gap: #{$size}px

.gap-auto
  gap: 20px
  @media (max-width: $laptop-view)
    gap: 16px
  @media (max-width: $tablet-view)
    gap: 12px
  @media (max-width: $mobile-view)
    column-gap: 8px
    row-gap: 12px