@import 'colors'

$screens: ('none':'', '-laptop': $laptop-view, '-tablet': $tablet-view, '-mobile': $mobile-view, 'important': '!important')
$colors: ('white': $white, 'grey': $border-grey, 'secondary-grey': $secondary-grey, 'table-grey': $table-bg-grey, 'blue': $tempton-blue, 'orange': $bright-orange, 'red': $bright-red, 'green': $bright-green)
$directions: ('t': '-top', 'r': '-right', 'b': '-bottom','l': '-left', '': '')
$widths: ('': '1px', '-2': '2px')
$border-radius: 4px
$border-radius-wide: 10px

@mixin borders( $color-key, $color, $direction-key, $direction, $width-key, $width, $screen-key: '', $important: '')
  .b#{$direction-key}-#{$color-key}#{$width-key}#{$screen-key}
    border#{$direction}: #{$width} solid #{$color} #{$important}
    @if $direction == ''
      border-radius: $border-radius

@each $screen-key, $screen in $screens
  @each $color-key, $color in $colors
    @each $direction-key, $direction in $directions
      @each $width-key, $width in $widths
        @if $screen-key == 'none'
          @include borders($color-key, $color, $direction-key, $direction, $width-key, $width)
        @else if $screen-key == 'important'
          @include borders($color-key, $color, $direction-key, $direction, $width-key, $width, $screen-key: '-important', $important: '!important')
        @else
          @media (max-width: $screen)
            @include borders($color-key, $color, $direction-key, $direction, $width-key, $width, $screen-key, '!important')


.bb-light-grey-5
  border-bottom: 5px solid $sidebar-separator-light-grey
.bb-blue-3
  border-bottom: 3px solid $tempton-blue

.bb-none
  border-bottom: none
  &-important
    border-bottom: none !important

.btl-radius
  border-top-left-radius: $border-radius-wide
.btr-radius
  border-top-right-radius: $border-radius-wide
.bbl-radius
  border-bottom-left-radius: $border-radius-wide
.bbr-radius
  border-bottom-right-radius: $border-radius-wide

.b-radius-wide
  border-radius: $border-radius-wide
.b-radius-wide-i
  border-radius: $border-radius-wide !important
