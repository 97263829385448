$spacers: ('m': margin, 'p': padding)
$directions: ('t': '-top', 'r': '-right', 'b': '-bottom', 'l': '-left', '': '')
$screens: ('none':'', '-laptop': $laptop-view, '-tablet': $tablet-view, '-mobile': $mobile-view)

@mixin spacers($spacer-key, $spacer, $direction-key, $direction, $screen: '', $important: '')
  @for $i from -3 to 8
    *.#{$spacer-key}#{$direction-key}#{$i * 5}#{$screen}
      #{$spacer}#{$direction}: #{$i * 5}px #{$important}
    @if $i == 0
      .#{$spacer-key}#{$direction-key}a#{$screen}
        #{$spacer}#{$direction}: auto #{$important}

@each $screen-key, $screen in $screens
  @each $spacer-key, $spacer in $spacers
    @each $direction-key, $direction in $directions
      @if $screen-key == 'none'
        @include spacers($spacer-key, $spacer, $direction-key, $direction)
      @else
        @media (max-width: $screen)
          @include spacers($spacer-key, $spacer, $direction-key, $direction, $screen-key, '!important')

@each $spacer-key, $spacer in $spacers
  @each $direction-key, $direction in $directions
    *.#{$spacer-key}#{$direction-key}-auto,
    *.#{$spacer}#{$direction}-auto
      #{$spacer}#{$direction}: 20px
      @media (max-width: $laptop-view)
        #{$spacer}#{$direction}: 16px
      @media (max-width: $tablet-view)
        #{$spacer}#{$direction}: 12px
      @media (max-width: $mobile-view)
        #{$spacer}#{$direction}: 7.5px

.ml115
  margin-left: 115px
.pb40
  padding-bottom: 40px
.pr-34-mobile
  @media (max-width: $mobile-view)
    padding-right: 34px

.p0
  padding: 0px !important

$sizes: ('xxs': 4px, 'xs': 8px, 's': 12px, 'm': 16px, 'l': 20px, 'xl': 24px, 'xxl': 32px, 'xxxl': 40px, 'xxxxl': 48px, 'xxxxxl': 56px)

@each $screen-key, $screen in $screens
  @each $spacer-key, $spacer in $spacers
    @each $direction-key, $direction in $directions
      @each $size-key, $size in $sizes
        @if $screen-key == 'none'
          *.#{$spacer-key}#{$direction-key}-#{$size-key}
            #{$spacer}#{$direction}: #{$size}
        @else
          @media (max-width: $screen)
            *.#{$spacer-key}#{$direction-key}-#{$size-key}#{$screen-key}
              #{$spacer}#{$direction}: #{$size} !important
